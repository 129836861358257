<template>
  <div class="my-package pt-3 pt-md-0">
    <!-- <section
      class="px-3 pt-md-3 pb-0 px-md-5 pt-md-5 pb-md-0 mt-0 mt-md-0 mt-lg-0"
    > -->
    <NavbarOne
      class="card-top d-md-none d-lg-none"
      id="card-top"
      :showNavbar="showNavbar"
      :showCart="showCart"
      :showCartDesktop="showCartDesktop"
      :showSearch="showSearch"
      :showLogo="showLogo"
    >
    </NavbarOne>
    <!-- </section> -->
    <v-card class="ma-3 mt-16 ma-md-5">
      <custom-section>
        <v-row dense>
          <v-col cols="12">
            <div class="d-block d-sm-flex">
              <v-btn
                v-for="(category, index) of getTestItemHeader"
                :key="index"
                v-on:click="filterPackages(category.groupName)"
                :id="`${category.groupName}`"
                class="btn-filter mr-3 mb-3 mb-md-0"
                :class="{ active: categoryActive == category.groupName }"
                outlined
              >
                {{ category.groupName }}
              </v-btn>

              <v-btn
                id="category"
                class="btn-filter mr-3 mb-3 mb-md-0"
                text
                v-on:click="filterPackages('all')"
                :class="{ active: categoryActive == 'all' }"
              >
                {{ $t("page.buy_package.by_category") }}
              </v-btn>
            </div>
          </v-col>
        </v-row>
        <!-- disabled sementara selain bali nrc -->
        <v-row dense class="mb-5">
          <v-col cols="12" v-for="(category, index) of arrTest" :key="index">
            <v-card-actions :id="`category${category.param}`" class="pa-0">
              <h3 class="title-page">{{ category.groupName }}</h3>
            </v-card-actions>
            <div>
              <v-row>
                <v-col
                  cols="12"
                  md="6"
                  lg="4"
                  v-for="service in category.groupDetail"
                  :key="service.id"
                >
                  <CardSubscribePackage :data="service" />
                </v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>
        <DialogMenu
          :tampilkan="dialog"
          @toggleDialog="dialog => toggleDialogMenu(dialog)"
        />
      </custom-section>
    </v-card>
  </div>
</template>

<script>
import CustomSection from "@/components/v2/section/index.vue";
import Auth from "@/service/auth_service";
import API from "@/service/api_service.js";
import DialogMenu from "@/components/v2/dialog/dialogMenu.vue";
import NavbarOne from "@/components/v2/topbar/navbar_one.vue";
import CardSubscribePackage from "@/components/v2/card/card_subscribe_package.vue";

export default {
  components: {
    CustomSection,
    DialogMenu,
    NavbarOne,
    CardSubscribePackage,
  },
  data: () => ({
    showNavbar: true,
    showCart: true,
    showCartDesktop: true,
    showSearch: true,
    showLogo: false,
    dialog: false,
    testBtn: "",
    packages_check: [],
    getCategory: [],
    selectBranch: [],
    items: [
      {
        title: "VIRTU DIGILAB MCU 1A",
        realPrice: 1350000,
        discountPrice: 938000,
        subTitle: "8 Test Covered",
      },
      {
        title: "VIRTU DIGILAB MCU 2A",
        realPrice: 1350000,
        discountPrice: 1203000,
        subTitle: "18 Test Covered",
      },
      {
        title: "VIRTU DIGILAB MCU 3A",
        realPrice: 2950000,
        discountPrice: 1584000,
        subTitle: "24 Test Covered",
      },
    ],
    testItem: [
      {
        dataCategory: [
          {
            id: 1,
            name: "VIRTU DIGILAB MCU 1A",
            prices: [
              {
                amount: "999000",
              },
              {
                amount: "111111",
              },
              {
                amount: "22222",
              },
            ],
            total_products: 11,
          },
          {
            id: 2,
            name: "VIRTU DIGILAB MCU 2A",
            prices: [
              {
                amount: "1999000",
              },
              {
                amount: "22222",
              },
              {
                amount: "21212",
              },
            ],
            total_products: 14,
          },
          {
            id: 3,
            name: "VIRTU DIGILAB MCU 3A",
            prices: [
              {
                amount: "2999000",
              },
              {
                amount: "33333",
              },
              {
                amount: "313131",
              },
            ],
            total_products: 18,
          },
        ],
        id: 1,
        name: "General",
        param: 1,
      },
      {
        dataCategory: [
          {
            id: 1,
            name: "VIRTU DIGILAB MCU 1A",
            prices: [
              {
                amount: "999000",
              },
              {
                amount: "111111",
              },
              {
                amount: "22222",
              },
            ],
            total_products: 11,
          },
          {
            id: 2,
            name: "VIRTU DIGILAB MCU 2A",
            prices: [
              {
                amount: "1999000",
              },
              {
                amount: "22222",
              },
              {
                amount: "21212",
              },
            ],
            total_products: 14,
          },
          {
            id: 3,
            name: "VIRTU DIGILAB MCU 3A",
            prices: [
              {
                amount: "2999000",
              },
              {
                amount: "33333",
              },
              {
                amount: "313131",
              },
            ],
            total_products: 18,
          },
        ],
        id: 2,
        name: "Company",
        param: 2,
      },
      {
        dataCategory: [
          {
            id: 1,
            name: "VIRTU DIGILAB MCU 1A",
            prices: [
              {
                amount: "999000",
              },
              {
                amount: "111111",
              },
              {
                amount: "22222",
              },
            ],
            total_products: 11,
          },
          {
            id: 2,
            name: "VIRTU DIGILAB MCU 2A",
            prices: [
              {
                amount: "1999000",
              },
              {
                amount: "22222",
              },
              {
                amount: "21212",
              },
            ],
            total_products: 14,
          },
          {
            id: 3,
            name: "VIRTU DIGILAB MCU 3A",
            prices: [
              {
                amount: "2999000",
              },
              {
                amount: "33333",
              },
              {
                amount: "313131",
              },
            ],
            total_products: 18,
          },
        ],
        id: 3,
        name: "Family",
        param: 3,
      },
    ],
    getTestItem: [],
    checkFilter: false,
    active: false,
    isActive: true,
    categoryActive: "all",
    getTestItemHeader: [],
    branch: "",
    paramBranchSLug: "",
    branchPar: "",
    getListPackage: [],
    listpackage: [],
    valid: true,
    groupPackage: [],
    arrTest: [],
    dataForFilter: [],
    dataFilterBranch: [],
  }),
  async mounted() {
    this.$store.commit("setLoading", true);
    // this.getParBranch = this.$route.query.branch;

    API.get(`${process.env.VUE_APP_API_TRUST}package/subscription`, {
      Authorization: `Bearer ${Auth.getToken()}`,
    }).then(res => {
      if (res.statusCode === 200) {
        let group = res.results.reduce((r, a) => {
          r[a.category] = [...(r[a.category] || []), a];
          return r;
        }, {});
        this.groupPackage = Object.entries(group).map(arr => ({
          groupName: arr[0],
          groupDetail: arr[1],
        }));
        this.arrTest = this.groupPackage;
        this.dataForFilter = this.groupPackage;
        this.getTestItemHeader = this.groupPackage;
      }
    });

    API.get(`${process.env.VUE_APP_API_TRUST}plant/get `, {
      Authorization: `Bearer ${Auth.getToken()}`,
    }).then(resp => {
      var newObject = resp.results.filter(function (el) {
        return el.type == "VIRTU DIGILABA";
      });
      this.selectBranch = newObject;
      // this.selectBranch = resp.results;
    });

    this.$store.commit("setLoading", false);
  },
  methods: {
    formater(value) {
      let val = (value / 1).toFixed(2).replace(".", ".");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    filterPackages(id) {
      this.categoryActive = id;

      if (id == "all") {
        this.arrTest = this.dataForFilter;
      } else {
        this.arrTest = this.dataForFilter.filter(dataFilter => {
          return dataFilter.groupName == id;
        });
      }
    },
    async openDialog() {
      this.dialog = true;
    },
    toggleDialogMenu(toggle) {
      this.dialog = toggle;
    },

    linkBranch(message, event) {
      event.preventDefault();
      this.$swal({
        icon: "error",
        text: message,
      }).then(() => {
        this.$refs.autoBranch.focus();
      });
    },
    validate() {
      if (this.$refs.form.validate() == true) {
        API.get(`${process.env.VUE_APP_API_TRUST}package/subscription`, {
          Authorization: `Bearer ${Auth.getToken()}`,
        }).then(res => {
          if (res.statusCode === 200) {
            let group = res.results.reduce((r, a) => {
              r[a.category] = [...(r[a.category] || []), a];
              return r;
            }, {});
            this.groupPackage = Object.entries(group).map(arr => ({
              groupName: arr[0],
              groupDetail: arr[1],
            }));
            this.arrTest = this.groupPackage;
            this.dataForFilter = this.groupPackage;
            this.getTestItemHeader = this.groupPackage;
          }
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/custom.scss";

.branch-selector {
  font-size: 12px;
  width: 162px;
}

@media screen and (max-width: 600px) {
  .btn-filter {
    width: 100% !important;
  }
  .branch-selector {
    width: 100% !important;
  }

  .text-field {
    margin-left: 0px !important;
    max-width: 85% !important;
  }

  .title-page {
    color: $blue_1;
    font-size: 14px;
    text-transform: capitalize !important;
  }

  .cart-right {
    text-align: right;
    justify-content: flex-end;
    background: none;
    width: 95%;
    margin-top: -19px;
    margin-left: 10px;
  }

  .cart {
    margin-left: 14px !important;
    margin-right: 58px;
    margin-top: -18px !important;
    z-index: 1;
  }

  .bell {
    margin-left: 8px;
  }

  .user-card {
    margin-top: -42px;
    max-width: 60% !important;
    margin-left: 142px;
  }

  .card-top {
    width: 100%;
    left: 0;
    right: 0;
  }

  .notification {
    height: 55px;
    border-radius: 3px;
    margin-top: -15px;
    margin-left: -80px;
  }

  .row-card {
    margin-top: -40px;
  }
}

.my-package {
  display: flex;
  flex-direction: column;
  padding: 1px 0 40px 0;
  a {
    text-decoration: none;
  }
  .h-full {
    height: 100%;
    border: 1px solid #777777;
  }
  &__section {
    padding: 0 20px;
  }
}
.title-page {
  color: $blue_1;
  margin-top: 6px;
  margin-bottom: 6px;
  font-size: 14px;
  text-transform: capitalize !important;
}

.text-field {
  max-width: 70%;
  margin-top: -3px;
}

.card-col {
  padding: 10px 20px;
}

.v-btn:not(.v-btn--round).v-size--default {
  min-width: 105px;
}
.theme--light.v-btn {
  color: #00a4b3;
  border-radius: 5px;
  font-weight: bold;
}

.theme--light.v-btn-toggle:not(.v-btn-toggle--group) {
  background: transparent;
}

.theme--light.v-btn-toggle:not(.v-btn-toggle--group) .v-btn.v-btn {
  border-color: #00a4b3 !important;
}

.v-btn:not(.v-btn--round).v-size--default {
  height: 33px;
  width: 162px;
  padding: 0 16px;
  text-transform: capitalize;
}

.v-btn {
  border-left-style: 1;
}

.active {
  background-color: #00a4b3;
  color: white !important;
}

.btn-filter {
  font-size: 12px;
}

.btn-detail {
  border-radius: 5px !important;
  width: 100% !important;
  background-color: #00a4b3 !important;
  color: white !important;
  text-transform: capitalize !important;
  font-weight: normal !important;
  border-radius: 4px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25) !important;
}

.btn-disabled {
  border-radius: 5px !important;
  width: 100% !important;
  background-color: grey !important;
  color: white !important;
  text-transform: capitalize !important;
  font-weight: normal !important;
  border-radius: 4px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25) !important;
}

.button {
  font-weight: normal !important;
  border-radius: 4px;
  background-color: #00a4b3 !important;
  color: white !important;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25) !important;
}
// .card--shadow {
//   border: thin solid rgba(0, 0, 0, 0.12) !important;
//   box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
//     0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%) !important;
// }
.cart-right {
  text-align: right;
  justify-content: flex-end;
  background: none;
  width: 95%;
  margin-top: -20px;
  float: right;
}
</style>

<style lang="scss">
.v-text-field--outlined.v-input--dense.v-text-field--outlined
  > .v-input__control
  > .v-input__slot {
  min-height: 30px !important;
}

.v-text-field--enclosed.v-input--dense:not(.v-text-field--solo).v-text-field--outlined
  .v-input__append-inner {
  margin-top: 5px !important;
}
</style>
